import React from "react"
import $ from "jquery"

export class Preloader extends React.Component {
  constructor(props) {
    super(props)
  }

  async fadePreloader() {
    $("#status")
      .delay(400)
      .fadeOut("slow")
    $("#preloader")
      .delay(500)
    .fadeOut("slow")
  }

  componentDidMount() {
    if (document.readyState === "complete") {
      this.fadePreloader()
    }
    $(window).on("load", () => {
      this.fadePreloader()
    })
  }
  render() {
    return (
      <div id="preloader" className="preloader wrapper">
        <div id="status" className="loadingio-spinner-ellipsis-tni2hivzbz">
          <div className="ldio-7vhc3xp0q9k">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    )
  }
}
