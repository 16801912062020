import React from "react"
import PropTypes from "prop-types"

export const Breadcrumb = ({ paths }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {paths.map((location, idx) => (
          <li className="breadcrumb-item" key={idx}>
            {location.link_url !== "#" || location.link_url ? (
              <a href={location.link_url}>{location.text}</a>
            ) : (
              location.text
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

Breadcrumb.propTypes = {
  paths: PropTypes.arrayOf({
    text: PropTypes.string.isRequired,
    link_url: PropTypes.string,
  }),
}
