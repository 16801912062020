import React, { useState, useContext, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Hidden } from "@material-ui/core"

import { siteMetaCtx } from "../store/siteMeta/context"

export const NavHeader = ({ siteTitle }) => {
  const siteMeta = useContext(siteMetaCtx)
  const [currentTab] = useState(siteMeta.uri)
  const [isNavDisplay, setIsNavDisplay] = useState(true)

  useEffect(() => {
    if (window.jQuery) {
      window.jQuery.scrollDetection({
        scrollDown: function() {
          if (document.documentElement.scrollTop > window.innerHeight)
            setIsNavDisplay(false)
        },
        scrollUp: function() {
          setIsNavDisplay(true)
        },
      })
    }
  }, [])

  const data = useStaticQuery(graphql`
    query {
      wordpressPost(tags: { elemMatch: { name: { eq: "header" } } }) {
        acf {
          logo {
            alt_text
            source_url
          }
          tabs {
            text
            link_url
          }
          lang {
            zh
            en
          }
          line_url
          facebook_url
        }
      }
    }
  `)
  const acf = data.wordpressPost.acf

  return (
    <header
      id="header"
      className={`w-100 d-flex header ${isNavDisplay ? "visible" : ""}`}
    >
      <nav className="navbar navbar-expand-lg bg-transparent p-0 w-100">
        <div className="row header-container align-items-center w-100">
          <div className="col-12 col-sm-6">
            <a className="navbar-brand" href="/">
              <img src={acf.logo.source_url} alt={acf.logo.alt_text} />
            </a>
          </div>
          <div
            className="col-auto col-sm-auto collapse navbar-collapse justify-content-around p-0 px-5 navbar-right"
            id="navbarbtn"
          >
            <div className="col-24 col-lg-18 p-0 justify-content-center align-items-center navbar-nav">
              <div className="row mt-3 mt-lg-0">
                {acf.tabs.map((tab, idx) => (
                  <div className="col-24 mb-2 col-lg-auto mb-lg-0" key={idx}>
                    <Link to={tab.link_url}>
                      <div
                        className={`navbar-nav-item ${
                          currentTab &&
                          (currentTab.includes(tab.text) ||
                            currentTab.includes(tab.link_url))
                            ? "active"
                            : ""
                        }`}
                      >
                        {tab.text}
                      </div>
                    </Link>
                  </div>
                ))}
                <Hidden mdUp>
                  <div className="col-auto p-0 p-md-auto ml-1">
                    <a href={acf.facebook_url} className="mx-2">
                      <img
                        src={require("../img/fb-logo.svg")}
                        alt="line_icon"
                      />
                    </a>
                    <a href={acf.line_url} className="mx-2">
                      {/* <i className="fab fa-line"></i> */}
                      <img
                        src={require("../img/line-logo.svg")}
                        alt="line_icon"
                      />
                    </a>
                  </div>
                </Hidden>
              </div>
            </div>
            <Hidden smDown>
              <div className="row col-24 col-lg-6 icons justify-content-start justify-content-md-end align-items-center m-0 ml-md-auto p-0 p-md-auto">
                <div className="col-auto  p-0 p-md-auto">
                  <a href={acf.facebook_url}>
                    <img src={require("../img/fb-logo.svg")} alt="line_icon" />
                  </a>
                  <a href={acf.line_url}>
                    {/* <i className="fab fa-line"></i> */}
                    <img
                      src={require("../img/line-logo.svg")}
                      alt="line_icon"
                    />
                  </a>
                </div>
                {/* <div className="dropdown"> */}
                {/* <button
                  className="btn btn-link navbar-button ml-3"
                  role="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  >
                  <span>{acf.lang.zh}</span>
                  <i className="material-icons align-bottom">expand_more</i>
                </button> */}
                {/* <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                  >
                  <a className="dropdown-item" href="#">
                  {acf.lang.zh}
                  </a>
                  <a className="dropdown-item" href="#">
                  {acf.lang.en}
                  </a>
                </div> */}
                {/* </div> */}
              </div>
            </Hidden>
          </div>
          <div className="col-auto ml-auto navbar-toggler-container">
            <button
              className="navbar-toggler navbar-toggler-right collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarbtn"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </nav>
    </header>
  )
}
