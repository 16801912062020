import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider, createMuiTheme } from "@material-ui/core"
import { Preloader } from "../ui/Preloader"
import "../scss/index.scss"

import { NavHeader } from "./NavHeader"
import { Footer } from "./Footer"

const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#e8cb74",
    },
  },
})

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ThemeProvider theme={MuiTheme}>
        <Preloader />
        <NavHeader siteTitle={data.site.siteMetadata.title} />
        <div>
          <main>{children}</main>
        </div>
        <Footer />
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
