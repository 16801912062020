import React from "react"
import ReactHtmlParser from "react-html-parser"
import { useStaticQuery, graphql } from "gatsby"

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      wordpressPost(tags: { elemMatch: { name: { eq: "footer" } } }) {
        id
        acf {
          background_image {
            source_url
            alt_text
          }
          logo {
            source_url
            alt_text
          }
          bottom_left_text
          bottom_right_text
          line_url
          facebook_url
        }
      }
    }
  `)
  const acf = data.wordpressPost.acf

  return (
    <footer
      className="footer footer-image"
      style={{ backgroundImage: `url(${acf.background_image.source_url})` }}
    >
      <div className=" container container-custom h-100">
        <div className="row mb-4">
          <div className="col col-sm-3">
            <img
              src={acf.logo.source_url}
              alt={acf.logo.alt_text}
              className="footer-logo_image"
            />
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-24 col-sm-12">
            <div className="flex-column justify-content-between content-container">
              {ReactHtmlParser(acf.bottom_left_text)}
            </div>
          </div>

          <div className="col-24 col-md-auto mt-4 mt-xl-0 align-self-end">
            <div className="d-flex col-24 p-0 justify-content-start justify-content-xl-end mb-2 icons">
              <a href={acf.facebook_url}>
                <img
                  src={require("../img/fb-logo.svg")}
                  alt="line_icon"
                  className="icon"
                />
              </a>
              <a href={acf.line_url}>
                <img
                  src={require("../img/line-logo.svg")}
                  alt="line_icon"
                  className="icon"
                />
              </a>
            </div>
            <div className="d-flex col-24 p-0 justify-content-start copyright">
              {ReactHtmlParser(acf.bottom_right_text)}
            </div>
            <div className="d-flex col-24 p-0 justify-content-start justify-content-xl-end copyright">
              Design by
              <img
                src={require("../img/Hwaling_logo.png")}
                alt="hwaling_logo"
                className="design_logo"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
