import React from "react"
import Img from "gatsby-image"

import { getImgFluid } from "../../utils/data"


export const RoomFullPage = ({ data }) => {
  console.log(getImgFluid(data.background_image));
  
  return (
    <section className="roomFullpage">
      <div>
        <div className="w-100 h-100">
          <Img
            fluid={getImgFluid(data.background_image)||''}
            alt={data.background_image?.alt_text}
            className="roomFullpage-img"
          />
        </div>
      </div>
      <div className="roomFullpage-textarea roomFullpage-frame row justify-content-center align-items-center">
        <div className="col">
          <div className="row roomFullpage-title mb-1">
            <p className="roomFullpage-title-text  mx-auto">{data.title}</p>
          </div>
          <div className="divider w-75 mx-auto my-2" />
          <div className="row roomFullpage-subtitle">
            <div className="p-0 roomFullpage-subtitle-text">
              {data.subtitle}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
